<template>
	<templateComp :apiUrl="apiUrl" :inf="inf" :kind="kind" :pr="ths" :searchKeyOpts="searchKeyOpts" />
</template>

<script>
import templateComp from 'pages/template'

export default {
	components: { templateComp },
	data() {
		return {
			kind: 'appPush',
			searchKeyOpts: [
				{ label: '템플릿 코드', value: 'template' },
				{ label: '템플릿 이름', value: 'name' },
				{ label: '제목', value: 'title' },
				{ label: '내용', value: 'content' },
			],
			inf: [
				{ title: 'No', model: 'idx', size: 2 },
				{ title: 'SEQ', key: 'pushNotificationTemplateSeq', size: 2 },
				{ title: '템플릿 코드', key: 'template', size: 3 },
				{ title: '종류', key: 'contentTypeString', size: 4 },
				{ title: '템플릿 이름', key: 'name', size: 6 },
				{ title: '제목', key: 'title', size: 8, notCenter: 1 },
				{ title: '내용', key: 'content', size: 20, notCenter: 1 },
				{ title: '더 알아보기', key: 'additionalData', size: 8 },
				{ title: '랜딩페이지', model: 'url', key: 'url', size: 6 },
				{ title: '알림함 랜딩페이지', model: 'url', key: 'inboxUrl', size: 6 },
				{ title: '생성일', key: 'createDt', size: 4 },
				{ title: '수정일', key: 'updateDt', size: 4 },

				{
					title: '수정',
					model: 'button',
					icon: 'pencil-square',
					size: 2,
					func: item => {
						this.templateComp.templatePopup.open(this.kind, true, item)
					},
				},
			],
			apiUrl: '/api/backoffice/pushNotificationSelect/templateList',
		}
	},
	methods: {},
	created() {},
}
</script>
